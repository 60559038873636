<template>
	<div id="SelectProject">
		<div class="SelectProjectBox">
			<div class="banner">
				<div class="logo">
					<img src="../../assets/imgs/logo.png">
				</div>
				<img class="back" src="../../assets/imgs/loginBack.png">
			</div>
			<div class="box">
				<h1>注册账号</h1>
				<ul class="steps">
					<template v-for="(item,i) in steps">
						<li :key="i" v-if="item.active<3">
							<div class="icon" v-if="item.active==2">
								0{{i+1}}
							</div>
							<div :class="item.active == 1?`icon active`:`icon`" v-if="item.active==1">
								<i class="el-icon-check" style="font-size:18px;"></i>
							</div>
							{{item.title}}
						</li>
					</template>
				</ul>
				<div class="StepBox">Step 4/{{steps.length}}</div>
				<p class="StepTitle">选择参与项目</p>
				<el-form :model="form" :rules="rules" ref="form" label-position="top">
					<el-form-item label="选择您要参加的项目" prop="value">
						<el-select v-model="form.value" placeholder="请选择您要参加的项目" filterable clearable>
							<el-option v-for="item in options" :key="item.projectName" :label="item.projectName"
								:value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<!-- <el-form-item label="选择您的参会身份：">
						<el-select v-model="form.value" placeholder="请选择您的参会身份">
							<el-option v-for="item in options" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</el-form-item> -->
					<el-form-item>
						<div class="options">
							<el-button class="bank" @click="bank()">返回</el-button>
							<el-button class="next" @click="onSubmit('form')">确认</el-button>
						</div>
					</el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		information,
		getProjectList,
		getDoctorInfo
	} from '@/request/api.js';
	export default {
		name: 'SelectProject',
		data() {
			return {
				options: [],
				form: {
					value: ''
				},
				rules: {
					value: [{
						required: true,
						message: '请输选择项目',
						trigger: 'blur'
					}],
				},
				steps: [{
					title: '创建账号',
					active: 1
				}, {
					title: '上传认证',
					active: 1
				}, {
					title: '完善信息',
					active: 1
				}, {
					title: '参与项目',
					active: 2
				}]
			}
		},
		created() {
			this.doctorInfo();
			this.getProjectListFun();
		},
		mounted() {},
		methods: {
			doctorInfo() {
				getDoctorInfo().then(res => {
					if (res.code == 200 && res.data.projectId) {
						this.form.value = res.data.projectId.toString();
					}
				})
			},

			getProjectListFun() {
				let data = {
					projectStatus: 'UNDERWAY'
				};
				getProjectList(data).then(res => {
					if (res.code == 200) {
						let resData = res.data.records;
						resData = resData.map(item => {
							return {
								id: item.id.toString(),
								projectName: item.projectName
							}
						})
						this.options = resData;
					}
				})
			},
			agreement() {
				console.log(1)
			},
			bank() {
				this.$router.go(-1)
			},
			onSubmit(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						// alert('submit!');
						let data = {
							projectId: this.form.value
						};
						information(data).then(res => {
							if (res.code == 200) {
								this.$notify({
									title: '提示',
									type: 'success',
									message: '上传成功',
									position: 'bottom-right'
								});
								this.$router.push({
									name: 'result'
								})
							} else {
								this.$notify({
									title: '提示',
									type: 'warning',
									message: res.msg,
									position: 'bottom-right'
								});
							};
						});
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			}
		}
	}
</script>

<style lang="scss" scoped>
	#SelectProject {
		height: 100vh;
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba(244, 249, 253, 1);

		.SelectProjectBox {
			width: 90%;
			height: 90%;
			display: flex;
			overflow: hidden;
			max-width: 1200px;
			border-radius: 20px;
			flex-direction: row;
			box-shadow: 0 0 20px rgba(0, 0, 0, .1);

			.banner,
			.box {
				width: 600px;
				background-color: #fff;
			}

			.banner {
				display: flex;
				align-items: center;
				flex-direction: column;
				justify-content: center;

				.logo {
					flex: 1;
					width: 100%;
					display: flex;
					flex-direction: row;
					align-items: flex-start;
					padding: 40px 0 0 40px;

					img {
						height: 80px;
					}
				}

				.back {
					width: 400px;
					margin-bottom: 100px;
				}
			}

			.box {
				display: flex;
				align-items: center;
				flex-direction: column;
				padding: 50px;
				overflow: hidden;
				overflow-y: auto;

				h1 {
					font-size: 20px;
					margin-bottom: 30px;
					color: #112950;
				}

				.steps {
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: flex-start;
					width: 100%;
					margin-bottom: 20px;

					li {
						display: flex;
						align-items: center;
						width: 25%;
						font-size: 17px;
						color: #112950;

						.icon {
							width: 30px;
							height: 30px;
							background: #007AFF;
							display: flex;
							align-items: center;
							justify-content: center;
							margin-right: 5px;
							border-radius: 5px;
							color: #fff;
							font-size: 14px;
						}

						.active {
							background: #ECF3FE;
							color: #007AFF;
						}
					}
				}

				.StepBox {
					width: 100%;
					font-size: 13px;
					color: #112950;
					margin-bottom: 20px;
				}

				.StepTitle {
					width: 100%;
					font-size: 22px;
					color: #112950;
					border-bottom: 1px solid #E5E5E5;
					padding-bottom: 10px;
					margin-bottom: 20px;
				}

				::v-deep .el-form {
					width: 100%;

					.el-form-item__label {
						padding: 0;
						line-height: 1;
						color: #112950;
					}

					.el-input__inner {
						border: none;
						border-bottom: 1px solid #E5E5E5;
						border-radius: 0;
					}

					.el-input-group__append {
						background-color: #fff;
						border: none;
						border-bottom: 1px solid #E5E5E5;
					}

					.el-select {
						width: 100%;
					}

					.el-checkbox {
						display: flex;
						align-items: center;
					}

					.options {
						display: flex;
						flex-direction: row;
						align-items: center;
						justify-content: center;

						.el-button {
							width: 300px;
							height: 50px;
							background-color: #007AFF;
							color: #fff;
						}

						.next {
							background-color: #007AFF;
							color: #fff;
						}

						.bank {
							background-color: #fff;
							color: #FF5733;
							border: 1px solid #FF5733;
						}
					}
				}
			}
		}
	}
</style>